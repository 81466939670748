import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import '../css/Home.css';
import '../css/backAboveButton.css';
import Slider from "../components/Slider";
import SEO from "../components/SEO";

function Home() {
    const bestelOptiesRef = useRef(null);
    //const location = useLocation();
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount

        const handleScroll = () => {
            if (window.scrollY > 20) {
                console.log("Scrolled!", window.scrollY);
                setShowButton(true);
            } else {
                setShowButton(false);
            }

            const content = document.querySelector('.contentHome-container');
            if (content) {
                const contentPosition = content.getBoundingClientRect().top;
                const screenPosition = window.innerHeight / 1.3;

                if (contentPosition < screenPosition) {
                    content.classList.add('fade-in');
                    window.removeEventListener('scroll', handleScroll);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup event listener on unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToBestel = (e) => {
        e.preventDefault();
        const bestelOptiesSection = document.getElementById("bestel-opties");
        const bestelTitle = document.querySelector('.bestel-opties-wrapper'); // Select the title element
        if (bestelOptiesSection && bestelTitle) {
            let targetElement = bestelOptiesSection;
            let headerOffset = 100; // Default offset

            // Check for smaller screens and adjust the target element to the title
            if (window.innerWidth <= 768) {
                // For smaller screens, scroll to the title
                targetElement = bestelTitle;
                headerOffset = 50; // Adjust offset for smaller screens
            }

            const elementPosition = targetElement.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });

            // Add the 'visible' class after scrolling
            setTimeout(() => {
                bestelOptiesSection.classList.add("visible");
            }, 600); // Delay should match the duration of the scroll
        }
    };

    useEffect(() => {
        const targetElement = bestelOptiesRef.current;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("visible");
                    }
                });
            },
            {
                threshold: 0.1, // Trigger when 10% of the element is visible
            }
        );

        if (targetElement) {
            observer.observe(targetElement);
        }

        return () => {
            if (targetElement) {
                observer.unobserve(targetElement);
            }
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const schemaMarkup = {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Ver-di Interieurbouw",
        "description": "Ver-Di interieurbouw zorgt voor maatwerk naar uw stijl. Wij doen voornamelijk keukens en badkamers maar ook voor maatwerk kan u ons inhuren.",
        "url": "https://ver-di-interieurbouw.be/",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "Houten Schoen 122",
            "addressLocality": "Sint-Niklaas",
            "addressRegion": "Oost-Vlaanderen",
            "postalCode": "9100",
            "addressCountry": "BE"
        },
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+32 478 98 30 24",
            "contactType": "Customer Service",
            "areaServed": "BE",
            "availableLanguage": "Dutch"
        }
    };

    return (
        <>
            <SEO
                title="Ver-di Interieurbouw | Maatwerk naar uw stijl | Interieurbouw sint niklaas | schrijnwerkerij"
                description="Ver-Di interieurbouw zorgt voor maatwerk naar uw stijl. Wij doen voornamelijk keukens en badkamers maar ook voor maatwerk kan u ons inhuren."
                keywords="Ver-Di Interieurbouw, Maatwerk interieurs, Keukens op maat, Badkamers op maat, Maatwerk meubels, Keukenrenovatie, Badkamerrenovatie, Interieur op maat, Maatwerk interieurdesign, Op maat gemaakte meubels, Interieurbouwer, Keukenontwerp, Badkamerontwerp, Persoonlijk interieuradvies, Vakmanschap interieur, Interieur op maat laten maken"
                canonical="https://ver-di-interieurbouw.be/"
                schemaMarkup={schemaMarkup}
            />
            <div className="home-component-wrapper">
                <div className="home-component">
                    <div className="home-content-wrapper">
                        <div className="test slider-wrapper">
                            <Slider />
                        </div>
                        <div className="home-content">
                            <div className="text-box">
                                <h1 className="title">Ver-Di</h1>
                                <p className="subtitle">Interieurprojecten</p>
                                <div className="buttons">
                                    <Link to="#bestel-opties" className="button donate" onClick={handleScrollToBestel}>
                                        Over Ons
                                    </Link>
                                    <Link to="/Contact" className="button event">
                                        Contacteer ons
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bestel-opties-wrapper">
                        <div className="bestel-opties" id="bestel-opties" ref={bestelOptiesRef}>
                            <div className="over-ons-container">
                                <div className="over-ons-text">
                                    <h2 className="bestelTitle">Over Ons</h2>
                                    <p>
                                        Ver-Di interieurbouw zorgt voor maatwerk naar uw stijl. Wij doen voornamelijk
                                        keukens en badkamers maar ook voor maatwerk kan u onze hulp inhuren. Hieronder
                                        kan u een overzicht vinden van onze projecten. Voor meer info kan u hier contact
                                        opnemen met ons.
                                    </p>
                                </div>
                                <div className="over-ons-image">
                                    <img src={require('../img/overOns/overOns.jpg')} alt="Over Ons" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* New Image Section */}
                    <div className="certificate-image-container" >
                        <img src={require('../img/cer/certificate.png')} alt="Certificate" />
                    </div>
                </div>
            </div>
            {showButton && (
                <button id="backToTopBtn" onClick={scrollToTop} title="Go to top">↑</button>
            )}
        </>
    );
}

export default Home;
