import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import '../css/FooterComponent.css'; // CSS file for styling the footer

const FooterComponent = () => {
    const phoneRef = useRef(null);
    const emailRef = useRef(null);
    const socialRef = useRef(null);
    const rightsRef = useRef(null);  // Ref for the footer rights

    useEffect(() => {
        const targets = [phoneRef.current, emailRef.current, socialRef.current, rightsRef.current];

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, { threshold: 0.1 });

        targets.forEach(target => {
            if (target) {
                observer.observe(target);
            }
        });

        // Cleanup observer on unmount
        return () => {
            targets.forEach(target => {
                if (target) observer.unobserve(target);
            });
        };
    }, []);

    return (
        <footer className="footer">
            <div className="footer-content">
                {/* Phone number */}
                <div className="contact-info-footer" ref={phoneRef}>
                    <FontAwesomeIcon icon={faPhone} className="iconcolor"/>
                    <a href="tel:+32478983024" className="contact-link">+32 478 98 30 24</a>
                </div>

                {/* Email address */}
                <div className="contact-info-footer" ref={emailRef}>
                    <FontAwesomeIcon icon={faEnvelope} className="iconcolor"/>
                    <a href="mailto:ver-di@telenet.be" className="contact-link">ver-di@telenet.be</a>
                </div>

                {/* Facebook icon linking to your Facebook page */}
                <a href="https://www.facebook.com/profile.php?id=100067808672569" target="_blank"
                   rel="noopener noreferrer"
                   className="social-icon" ref={socialRef}>
                    <FontAwesomeIcon icon={faFacebookF}/>
                </a>
            </div>
            {/* Footer rights */}
            <p className="footer-rights" ref={rightsRef}>© 2024. All rights reserved | Ondernemingsnummer:
                0645.488.676 | <a href="https://hostedevelopment.com/" target="_blank" rel="noopener noreferrer"
                                className="footer-link">
                    developed by hostedevelopment
                </a></p>

        </footer>
    );
};

export default FooterComponent;
